import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { initFormController } from '@wix/form-viewer/controller';
import { getComponentSettings } from '@wix/ambassador-app-settings-v1-settings/http';

const FORM_COMPOSER_DEV_NAMESPACE = 'wix.form_composer_dev.form';
const componentId = 'vidmantas-onboarding-task';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const readSettings = async () => {
    const read = await flowAPI.httpClient.request(
      getComponentSettings({
        componentId,
      }),
    );
    return read.data.settings ?? {};
  };

  const { setProps } = controllerConfig;
  return {
    async pageReady() {
      const settings = await readSettings();
      const formId = settings.forms?.pop(); // simply show last form created

      try {
        await initFormController(flowAPI, {
          formId,
          namespace: FORM_COMPOSER_DEV_NAMESPACE,
        });
      } catch (err) {
        console.log('---initFormController failed', err);
      }

      setProps({
        formId,
        appSettings: {},
      });
    },
  };
};

export default createController;
