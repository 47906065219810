
  import userController from '/home/builduser/work/1734d8c5a0cd4392/packages/vidmantas-onboarding-task-fed/src/components/VidmantasOnboarding/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://723b56cadfeb459684791f5d3dec480f@sentry.wixpress.com/5815',
    id: 'undefined',
    projectName: 'vidmantas-onboarding-task-fed',
    teamName: 'vidmantas-onboarding-task-fed',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/1734d8c5a0cd4392/packages/vidmantas-onboarding-task-fed/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/1734d8c5a0cd4392/packages/vidmantas-onboarding-task-fed/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "vidmantas-onboarding-task-fed",
    componentName: "VidmantasOnboarding",
    appDefinitionId: "b2caa24c-4b4e-4e1c-bf5a-64407f6525b5",
    componentId: "bc69b64c-27c5-4760-b1ed-c8a794648790",
    projectName: "vidmantas-onboarding-task-fed",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/1734d8c5a0cd4392/packages/vidmantas-onboarding-task-fed/src/components/VidmantasOnboarding/controller.ts",
  };

  export const controller = _controller
  export default controller;
